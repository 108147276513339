<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters(["tenant"]),
  },
  data() {
    return {
      locale: zhCN,
    };
  },
  metaInfo() {
    return {
      title: "工业互联网平台",
      // title: this.tenant?.websiteTitle,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.tenant?.websiteDescription,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.tenant?.websiteKeywords?.join(","),
        },
      ],
    };
  },
};
</script>
<style>
#app {
  height: 100%;
}
</style>
