import { getTotalDictItems } from "@/api/dict";
import Vue from "vue";

export default {
    state: {
        dict: {
            USER_VERIFY_STATUS: [
                { itemValue: "1", itemText: "待审核" },
                { itemValue: "2", itemText: "实名中" },
                { itemValue: "3", itemText: "实名成功" },
                { itemValue: "4", itemText: "实名失败" },
            ],
            COMPANY_VERIFY_STATUS: [
                { itemValue: "1", itemText: "待审核" },
                { itemValue: "2", itemText: "认证中" },
                { itemValue: "3", itemText: "认证成功" },
                { itemValue: "4", itemText: "认证失败" },
            ],
            COMPANY_APPLIER_TYPE: [
                { itemValue: "1", itemText: "法定代表人" },
                { itemValue: "2", itemText: "授权委托人" },
            ],
            REGISTER_TYPE: [
                { itemValue: "1", itemText: "手机号注册" },
                // { itemValue: "2", itemText: "邮箱注册" },
            ],
            REGION_STATUS: [
                { itemValue: "1", itemText: "开启" },
                { itemValue: "2", itemText: "关闭" },
            ],
            VALID_STATUS: [
                { itemValue: "1", itemText: "启用" },
                { itemValue: "2", itemText: "已停用" },
            ],
            CONSULT_STATUS: [
                { itemValue: "1", itemText: "待处理" },
                { itemValue: "2", itemText: "已受理" },
                { itemValue: "3", itemText: "已回复" },
                { itemValue: "4", itemText: "已关闭" },
            ],
            SCENE_STATUS: [
                { itemValue: "1", itemText: "草稿中" },
                { itemValue: "2", itemText: "审核中" },
                { itemValue: "3", itemText: "审核不通过" },
                { itemValue: "4", itemText: "严选发榜中" },
                { itemValue: "5", itemText: "严选不通过" },
                { itemValue: "6", itemText: "揭榜中" },
                { itemValue: "7", itemText: "已终止" },
                { itemValue: "8", itemText: "已定帅" },
            ],
            SCENE_STATUS_CUSTOM: [
                { itemValue: "SCN_STATUS_FOR_BIDDER_01", itemText: "待申请" },
                { itemValue: "SCN_STATUS_FOR_BIDDER_02", itemText: "已申请" },
                { itemValue: "SCN_STATUS_FOR_BIDDER_03", itemText: "已中榜" },
                { itemValue: "SCN_STATUS_FOR_BIDDER_04", itemText: "未中榜" },
                { itemValue: "SCN_STATUS_FOR_BIDDER_05", itemText: "已过期" },
                { itemValue: "SCN_STATUS_FOR_BIDDER_06", itemText: "已终止" },
                { itemValue: "SCN_STATUS_FOR_BIDDER_07", itemText: "我是场景需求方" },
            ],
            NEWS_TYPE: [
                { itemValue: "1", itemText: "政策" },
                { itemValue: "2", itemText: "资讯" },
            ],
            NEWS_SUBTYPE: [
                { itemValue: "1", itemText: "一般发文" },
                { itemValue: "2", itemText: "申报奖励" },
            ],
            NEWS_LEVEL: [
                { itemValue: "1", itemText: "国家级别" },
                { itemValue: "2", itemText: "省级" },
                { itemValue: "3", itemText: "市级" },
                { itemValue: "4", itemText: "区级" },
                { itemValue: "5", itemText: "县级" },
            ],
            COMPANY_TYPE: [
                { itemValue: "1", itemText: "制造商" },
                { itemValue: "2", itemText: "方案商" },
                { itemValue: "3", itemText: "品牌商" },
                { itemValue: "4", itemText: "集成商" },
            ],
            COMPANY_SCALE_TYPE: [
                { itemValue: "1", itemText: "1-10人" },
                { itemValue: "2", itemText: "11-50人" },
                { itemValue: "3", itemText: "51-100人" },
                { itemValue: "4", itemText: "101人-1000人" },
                { itemValue: "5", itemText: "1001人以上" },
            ],
            QUESTIONNAIRE_TYPE: [
                { itemValue: "1", itemText: "产业调研" },
                { itemValue: "2", itemText: "国产化需求调研" },
                { itemValue: "3", itemText: "政策调研" },
                { itemValue: "4", itemText: "其他" },
            ],
            QUESTION_TYPE: [
                { itemValue: "1", itemText: "单选题" },
                { itemValue: "2", itemText: "多选题" },
                { itemValue: "3", itemText: "单选下拉单" },
                { itemValue: "4", itemText: "多选下拉单" },
                { itemValue: "5", itemText: "单行文本" },
                { itemValue: "6", itemText: "多行文本" },
                { itemValue: "7", itemText: "矩阵题" },
                { itemValue: "8", itemText: "表格题" },
            ],
            ORG_TYPE: [
                { itemValue: "1", itemText: "主办单位" },
                { itemValue: "2", itemText: "承办单位" },
                { itemValue: "3", itemText: "协办单位" },
                { itemValue: "4", itemText: "指导单位" },
            ],
            CONTACT_TYPE: [
                { itemValue: "1", itemText: "手机号" },
                { itemValue: "2", itemText: "座机" },
                { itemValue: "3", itemText: "邮箱" },
            ],
            ACTIVITY_MODE: [
                { itemValue: "1", itemText: "线上" },
                { itemValue: "2", itemText: "线下" },
            ],
            DEVICE_OS: [
                { itemValue: '1', itemText: 'Android' },
                { itemValue: '2', itemText: 'Windows' },
                { itemValue: '3', itemText: 'Linux' },
                { itemValue: '4', itemText: 'FreeRTOS' },
                { itemValue: '5', itemText: '统信' },
                { itemValue: '6', itemText: '麒麟' },
                { itemValue: '7', itemText: '元心' },
                { itemValue: '8', itemText: '其他' }
            ],
            DEVICE_SOC: [
                { itemValue: '1', itemText: 'IT' },
                { itemValue: '2', itemText: '海思' },
                { itemValue: '3', itemText: 'ST' },
                { itemValue: '4', itemText: 'MTK' },
                { itemValue: '5', itemText: '高通' },
                { itemValue: '6', itemText: 'NXP' },
                { itemValue: '7', itemText: '英飞凌' },
                { itemValue: '8', itemText: '其他' }
            ]
        },
    },
    mutations: {
        SET_DICT_DATA(state, data) {
            Vue.set(state.dict, data.code, data.data);
        },
    },
    actions: {
        LoadDictData({ commit, state }, codes) {
            return new Promise((resolve, reject) => {
                const dictCodes = codes.filter((dictCode) => !state.dict[dictCode]);
                if (!dictCodes.length) {
                    resolve(state.dict);
                    return;
                }
                getTotalDictItems({ dictCodes }).then((list) => {
                    const obj = {};
                    for (let i = 0; i < list.length; i++) {
                        const code = list[i].dictCode;
                        if (!obj[code]) {
                            obj[code] = [];
                        }
                        obj[code].push(list[i]);
                    }
                    Object.keys(obj).forEach((code) => {
                        commit("SET_DICT_DATA", {
                            code,
                            data: obj[code],
                        });
                    });
                    resolve(state.dict);
                });
            });
        },
        ClearDict({ commit }, dictCodes) {
            dictCodes.forEach((code) => {
                commit("SET_DICT_DATA", {
                    code,
                    data: [],
                });
            });
        },
    },
};
