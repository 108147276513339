import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);
Vue.use(VueMeta);
const routes = [
  {
    path: "/register",
    name: "register",
    component: () => import("@/page/register"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () => import("@/page/forgetPassword"),
  },
  {
    path: "/newPassword",
    name: "newPassword",
    component: () => import("@/page/forgetPassword/newPassword"),
  },
  {
    path: "/emailCode",
    name: "emailCode",
    component: () => import("@/page/forgetPassword/email"),
  },
  {
    path: "/",
    name: "layout",
    component: () => import("@/page/layout"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/page/home"),
      },
      {
        path: "/gitIot",
        name: "gitIot",
        component: () => import("@/page/gitIot"),
      },
      {
        path: "/market",
        name: "market",
        component: () => import("@/page/market"),
      },
      {
        path: "/solution",
        name: "solution",
        component: () => import("@/page/solution"),
      },
      {
        path: "/solution/info",
        name: "solutionInfo",
        component: () => import("@/page/solution/info"),
      },
      {
        path: "/solution/ordinary_program",
        name: "ordinary_program",
        component: () => import("@/page/solution/ordinary_program"),
      },
      {
        path: "/solution/internet_things",
        name: "internet_things",
        component: () => import("@/page/solution/internet_things"),
      },
      {
        path: "/solution/digitizing",
        name: "digitizing",
        component: () => import("@/page/solution/digitizing"),
      },
      {
        path: "/replace",
        name: "replace",
        component: () => import("@/page/replace"),
      },
      {
        path: "/replace/info",
        name: "replaceInfo",
        component: () => import("@/page/replace/info"),
      },
      {
        path: "/replace/productInfo/:type",
        name: "productInfo",
        component: () => import("@/page/replace/productInfo"),
      },
      {
        path: "/activity",
        name: "activity",
        component: () => import("@/page/activity"),
      },
      {
        path: "/activity/courseInfo",
        name: "courseInfo",
        component: () => import("@/page/activity/courseInfo"),
      },
      {
        path: "/activity/activityInfo",
        name: "activityInfo",
        component: () => import("@/page/activity/activityInfo"),
      },
      {
        path: "/searchInfo",
        name: "searchInfo",
        component: () => import("@/page/searchInfo"),
      },
      {
        path: "/protocol",
        name: "protocol",
        component: () => import("@/page/protocol"),
      },
      {
        path: "/protocol/serve",
        name: "protocolServe",
        component: () => import("@/page/protocol/serve"),
      },
    ],
  },
  // {
  //   path: "/userLayout",
  //   component: () => import("@/page/userLayout"),
  //   children: [
  //     {
  //       path: "/userLayout",
  //       name: "userHome",
  //       meta: {
  //         type: "info",
  //       },
  //       component: () => import("@/page/userHome"),
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
