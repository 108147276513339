import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";

import getters from "./getters";
import dict from "./modules/dict";
import tenant from "./modules/tenant";
import settings from "./modules/settings";
import msg from "./modules/msg";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    dict,
    tenant,
    settings,
    msg
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
});
