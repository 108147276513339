import { PortalRequest as request } from "@/utils/request";

// 分页查看我的消息
export const getMessageList = (params,data) => {
    return request({
        url: "/message/page-list-me",
        method: "post",
        params,
        data,
    });
};

// 根据消息id删除消息
export const getMessageRemove = (id) => {
    return request({
        url: `/message/${id}/remove`,
        method: "delete",
    });
};

// 根据消息id列表批量修改为已读
export const getMessageRead = (data) => {
    return request({
        url: "/message/set-has-read",
        method: "post",
        data,
    });
};

// 根据消息id列表批量删除消息
export const getMessageRemoveAll = (data) => {
    return request({
        url: "/message/remove",
        method: "post",
        data,
    });
};

// 获取未读消息总数
export const getMessageCount = () => {
    return request({
        url: "/message/get-unread-count",
        method: "get",
    });
};