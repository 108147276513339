import { PortalRequest as request } from "@/utils/request";

/**
 * 租户列表
 * @returns {*}
 */
export const getTenantList = () => {
  return request({
    url: "/common/tenant-list",
    method: "get",
  });
};

/**
 * 获取验证码
 * @returns {*}
 */
export const getCaptcha = () => {
  return request({
    url: "/common/captcha",
    method: "get",
  });
};

/**
 * 校验验证码
 * @param data
 * @returns {*}
 */
export const checkCaptcha = ({ key, code }) => {
  return request({
    url: "/common/check-captcha",
    method: "post",
    data: { key, code },
  });
};
export const checkCaptchaMobile = (data) => {
  return request({
    url: "/common/check-mobile-captcha",
    method: "post",
    data,
  });
};

/**
 * 发送邮箱验证码
 * @param email
 * @returns {*}
 */
export const sendEmail = (email) => {
  return new Promise((resolve, reject) => {
    request({
      url: `/common/send-email/${email}`,
      method: "post",
    })
      .then(() => {
        getEmailCode(email)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * 获取邮箱验证码
 * TODO 该接口未来会删除
 * @param email
 * @returns {*}
 */
export const getEmailCode = (email) => {
  return request({
    url: `/common/get-email-code/${email}`,
    method: "get",
  });
};

/**
 * 发送手机验证码
 * @param mobile
 * @param type password 密码找回,bind 绑定手机修改,login 登录注册
 *
 * @returns {*}
 */
export const sendMobile = (mobile, type) => {
  return request({
    url: `/common/send-sms/${mobile}`,
    method: "post",
    params: { type },
  });
};
/**
 * 下载
 * @param {*} id
 * @returns
 */
export const getFileDownload = (id) => {
  return request({
    url: `/file/download/${id}`,
    method: "get",
  });
};

/**
 * 获得当前租户
 * @returns {*}
 */
export const getCurrentTenant = () => {
  return request({
    url: "/common/tenant",
    method: "get",
  });
};

/**
 * 获得系统配置
 * @returns {*}
 */
export const getSettings = () => {
  return request({
    url: "/common/settings",
    method: "get",
  });
};

/**
 * 获得当前租户导航
 * @returns {*}
 */
export const getTenantNavigations = (tenantId) => {
  return request({
    url: `/common/tenant-navigations/${tenantId}`,
    method: "get",
  });
};

/**
 * 获得当前租户友情链接
 * @returns {*}
 */
export const getTenantLinks = (tenantId) => {
  return request({
    url: `/common/tenant-links/${tenantId}`,
    method: "get",
  });
};

/**
 * 获得当前租户广告列表
 * @returns {*}
 */
export const getTenantAds = (tenantId) => {
  return request({
    url: `/common/tenant-ads/${tenantId}`,
    method: "get",
  });
};

/**
 * 重置密码校验手机验证码，后台会生成临时token来标识要修改的手机号返回到前台（临时token位5分钟有效期，短信验证码也是5分钟）
 * @param mobile
 * @param code
 * @returns {AxiosPromise}
 */
export const checkMobileByReset = ({ mobile, code }) => {
  return request({
    url: `/common/check-mobile-by-reset`,
    method: "post",
    data: { mobile, code },
  });
};
/**
 * 根据获取的临时token来修改密码
 * @param token
 * @param password
 * @returns {AxiosPromise}
 */
export const resetPasswordByMobile = ({ token, password }) => {
  return request({
    url: `/common/mobile-reset-password`,
    method: "post",
    data: { token, password },
  });
};

/**
 * 获取文件
 * @param id
 * @returns {AxiosPromise}
 */
export const getAttachment = (id) => {
  return request({
    url: `/file/attachment/${id}`,
    method: "get",
  });
};

/**
 * 获取文件列表
 * @returns {*}
 */
export const getAttachments = (ids) => {
  return request({
    url: "/file/attachments",
    method: "get",
    params: {
      ids: ids.join(","),
    },
  });
};

export const getH5path = () => {
  return request({
    url: `/weixin/getH5url`,
    method: "get",
  });
};

/**
 * 云圆热门搜索
 * @param data
 * @returns {*}
 */
export const getHotList = (code) => {
  return request({
    url: `yunyuan/search-hot-word/${code}/0/7`,
    method: "get",
  });
};
