import { getMessageCount } from "@/api/message";

export default {
  state: {
    msg: null,
  },
  mutations: {
    SET_MSG(state, msg) {
      state.msg = msg;
    },
  },
  actions: {
    LoadMsg({ commit }) {
      return new Promise((resolve) => {
        getMessageCount().then((data) => {
          commit("SET_MSG", data);
          resolve(data);
        });
      });
    },
  },
};
