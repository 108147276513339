import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";

const getters = {
  access_token: () => storage.get(ACCESS_TOKEN),
  userInfo: (state) => state.user.info,
  isLogin: (state) => !!state.user.info,
  tenant: (state) => state.tenant.tenant,
  settings: (state) => state.settings.settings,
  dict: (state) => state.dict.dict,
  msg: (state) => state.msg.msg,
  dictText: (state) => (code, value) => {
    const list = state.dict.dict[code];
    const arr = (list || [])
      .filter((e) => e.itemValue === value)
      .map((e) => e.itemText);
    // eslint-disable-next-line no-mixed-operators
    return (arr && arr.length && arr[0]) || "";
  },
  redirectUri: (state) =>
    process.env.NODE_ENV === "development"
      ? "http://localhost/authorized"
      : `${process.env.VUE_APP_REDIRECT_URI}`,
  companyId: (state) => state.user.company_id,
  companyList: (state) => state.user.company_list,
  companyInfo: (state) =>
    state.user.company_list.filter((e) => e.id === state.user.company_id)[0] ||
    {},
};

export default getters;
